import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import logger from "common/utilities/logger";
import appConstant from "common/constants/appConstant";
import PaymentDetails from "components/paymentDetails";
import ROUTE_CONSTANTS from "common/constants/route";

const ProspectDetails = ({
    getCustomer,
    getCartDetails,
    setError,
    isError,
    isApiError,
    setIsApiError,
    refreshComponent,
    isCheckBoxError,
    setCheckBoxError,
}) => {
    let history = useHistory();

    const { prospectData, sdkConfig, existingCustomer } = useSelector((state) => state.userDetails);

    const [authorizationSetUpIntent, setAuthorizationSetUpIntent] = useState("");
    const [paymentKeys, setPaymentKeys] = useState({});
    const [paymentType, changePaymentType] = useState({});

    const { country, details, pets, id } = prospectData;
    const { email } = details[0];
    const { zipcode } = pets[0];

    let countryCode = country;
    if (countryCode === appConstant.COUNTRY_CODE_CANADA) {
        countryCode = appConstant.COUNTRY_CODE_CA;
    }

    useEffect(() => {
        const getCart = async () => {
            try {
                await getCustomer(email);

                const fetchReRateCartData = await getCartDetails(email, zipcode);

                const reRateCartData =
                    fetchReRateCartData &&
                    fetchReRateCartData.payload &&
                    fetchReRateCartData.payload.response &&
                    fetchReRateCartData.payload.response.cartResponse;

                const getCustomerDetails = reRateCartData && reRateCartData.customer;

                if (reRateCartData && reRateCartData?.isPaymentOnHold) {
                    logger.error("Policy in progress through your employee benefits.");

                    history.push(ROUTE_CONSTANTS.ERROR);

                    return;
                }

                if (getCustomerDetails) {
                    setAuthorizationSetUpIntent(`Bearer ${sdkConfig?.bearerToken}`);

                    logger.info("Rendered prospect details component successfully");
                } else {
                    logger.error("Error in get cart response", getCustomerDetails);

                    history.push(ROUTE_CONSTANTS.ERROR);
                }
            } catch (error) {
                logger.error("Error in get customer details API:", error);

                history.push(ROUTE_CONSTANTS.ERROR);
            }
        };

        getCart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {authorizationSetUpIntent && (
                <PaymentDetails
                    setPaymentKeys={setPaymentKeys}
                    paymentKeys={paymentKeys}
                    authorizationSetUpIntent={authorizationSetUpIntent}
                    countryCode={countryCode}
                    prospectId={existingCustomer.isExist ? existingCustomer.customerExternalRef : id}
                    setError={setError}
                    isError={isError}
                    isApiError={isApiError}
                    setIsApiError={setIsApiError}
                    refreshComponent={refreshComponent}
                    paymentType={paymentType}
                    changePaymentType={changePaymentType}
                    isCheckBoxError={isCheckBoxError}
                    setCheckBoxError={setCheckBoxError}
                />
            )}
        </div>
    );
};

export default ProspectDetails;

ProspectDetails.propTypes = {
    prospectData: PropTypes.object,
    getCustomer: PropTypes.func,
    getCartDetails: PropTypes.func,
    setError: PropTypes.func,
    isError: PropTypes.bool,
    setIsApiError: PropTypes.func,
    isApiError: PropTypes.bool,
    refreshComponent: PropTypes.func,
    isCheckBoxError: PropTypes.object,
    setCheckBoxError: PropTypes.func,
};
